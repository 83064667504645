/* .box {
    margin-top: 20px;
} */

.summary {
    text-align: center;
    max-width: 45rem;
    width: 90%;
    margin: auto;
    margin-top: -10rem;
    position: relative;
    background-color: #ffff;
    border-radius: 14px;
    padding: 1rem;
    box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
  }
  
  .summary h2 p {
    font-size: 2rem;
    margin-top: 0;
    color: #0000;
  }

  /* .summary ul {
    text-decoration: none;
    text-align: center;
  }

  .summary li {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .main-image {
    width: 100%;
    height: 30rem;
    z-index: 0;
    overflow: hidden;
  }
  
  .main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  