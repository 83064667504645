.summary {
  text-align: center;
  max-width: 45rem;
  width: 90%;
  margin: auto;
  margin-top: -6rem;
  position: relative;
  background-color: #46884a;
  color: white;
  border-radius: 14px;
  padding: 8px;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
}

.summary h2 {
  font-size: 2rem;
  margin-top: 0;
}

.summary ul {
  text-decoration: none;
  text-align: center;
}

.summary li {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
}

.summary li:hover {
  border-radius: 3px;
  color: #000000;
}

.main-image {
  width: 100%;
  height: 30rem;
  z-index: 0;
  overflow: hidden;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}