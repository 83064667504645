.box {
    margin-top: 30px;
    padding-top: 40px;
}

.text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #ffff;
}

.contact {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.Button {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
}